<template>

  <!-- Billing Checkout Dialog Card -->
  <div class="adm-billing-dialog-plans-card">
    <div
      class="adm-billing-dialog-plans-card__container"
      :class="{
        'is-active': isActive,
        'is-blue': plan.name === 'scaling_v2' && !isPromoVisible,
        'promotion': isPromoVisible && (plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible))
      }"
    >

      <!-- Best Value Plan -->
      <div v-if="plan.name === 'scaling_v2'" class="adm-billing-dialog-plans-card__container__best-value"
           :class="{'adm-billing-dialog-plans-card__container__best-value--promotion': isPromoVisible}"
      >
        <div class="adm-billing-dialog-plans-card__container__best-value__tooltip">
          <div class="adm-billing-dialog-plans-card__container__best-value__tooltip__frame"
               :class="{'adm-billing-dialog-plans-card__container__best-value__tooltip__frame--promotion': isPromoVisible}"
          >
            <div class="adm-billing-dialog-plans-card__container__best-value__tooltip__frame__content"
                 :class="{'adm-billing-dialog-plans-card__container__best-value__tooltip__frame__content--promotion': isPromoVisible}"
            >
              {{ $t('best_value') }}
            </div>
          </div>
          <div class="adm-billing-dialog-plans-card__container__best-value__tooltip__bullet"
               :class="{'adm-billing-dialog-plans-card__container__best-value__tooltip__bullet--promotion': isPromoVisible}"
          >
            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="5" cy="5" r="5" fill="#E6EFFE"/></svg>
          </div>
        </div>
      </div>
      <!-- Best Value Plan -->

      <!-- Discount -->
      <div v-if="plan.coupon" class="adm-billing-dialog-plans-card__container__free-promo-discount">
        {{ getCouponDiscountValue(plan) }}
      </div>
      <!-- /Discount -->

      <div class="adm-billing-dialog-plans-card__wrapper">

        <!-- Plan Name -->
        <div
          class="adm-billing-dialog-plans-card__title"
          :class="{'is-blue': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible),
                   'promo-billing-2024': isPromoVisible,
          }"
        >
          <span v-if="loading === false" class="adm-billing-dialog-plans-card__title__name"
                :class="{'is-blue': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible),
                         'promo-billing-2024': isPromoVisible
                }"
          >
            {{ getPlanLabel(plan) }}
          </span>
          <span v-if="loading === false" v-show="isPromoVisible" class="adm-billing-dialog-plans-card__title__black-friday-users">
            {{ plan.maxNumberOfEmployees }} {{ $t('users') }}
          </span>

          <!-- Active Plan -->
          <span
            v-if="visibleActivePlan && !isPromoVisible"
            class="adm-billing-dialog-plans-card__title__badge adm-billing-dialog-plans-card__title__badge--active"
            :class="{'is-blue': plan.name === 'scaling_v2'}"
          >
            {{ $t('active') }}
          </span>
          <!-- /Active Plan -->

          <!-- Plan Savings-->
          <span
            v-else-if="!loading && plan.billingInterval === 'yearly' && plan.name !== 'enterprise' && plan.name !== 'free_v2' && !isPromoVisible"
            class="adm-billing-dialog-plans-card__title__badge adm-billing-dialog-plans-card__title__badge--saving"
            :class="{'is-blue': plan.name === 'scaling_v2'}"
          >
            {{ getPlanSavings(plan.name) }}
          </span>

          <adm-skeleton-element v-if="loading" :height="24" :width="120" />
        </div>
        <!-- /Plan Name -->

        <!-- Coupon -->
        <div
          v-if="plan.coupon"
          class="adm-billing-dialog-plans-card__coupon mt-20 flex-column flex-center"
        >
          <div class="adm-billing-dialog-plans-card__coupon__without-coupon" :class="{'is-blue': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible)}">
            <span
              :class="{
                'is-blue': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible),
                'promo-billing-2024-discount-price': getPlanPrice().toString().length > 6,
                'promotion': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible),
              }"
            >
              {{ getPlanPrice(false) }}
            </span>
          </div>

          <!--          <div class="adm-billing-dialog-plans-card__coupon__without-coupon">-->
          <!--            <span>{{ getPlanPrice() }}</span>-->
          <!--          </div>-->
        </div>
        <!-- /Coupon -->

        <!-- Plan Price and Tax -->
        <div
          v-if="loading === false"
          class="adm-billing-dialog-plans-card__price-section"
          :class="{'promo-billing-2024': isPromoVisible,
                   'promo-billing-2024-free': plan.name === 'free_v2' && plan.priceForUser?.isThereATax && isPromoVisible,
                   'promo-billing-2024-free-tax': plan.name === 'free_v2' && !plan.priceForUser?.isThereATax && isPromoVisible,
                   'promo-billing-2024-enterprise': plan.name === 'enterprise' && plan.price?.tax === 0 && isPromoVisible,
                   'promo-billing-2024-enterprise-tax': plan.name === 'enterprise' && plan.price?.tax !== 0 && isPromoVisible,
          }"
        >

          <div v-if="plan.name === 'enterprise'"
               class="adm-billing-dialog-plans-card__price-section__starting"
               :class="{'promo-billing-2024': isPromoVisible}"
          >
            {{ $t('starting_at') }}
          </div>
          <!-- Plan Price -->
          <div class="adm-billing-dialog-plans-card__price-section__value"
               :class="{'promo-billing-2024': plan.coupon,
                        'promo-billing-2024-free': plan.name === 'free_v2' && isPromoVisible && plan.priceForUser?.isThereATax,
                        'promo-billing-2024-free-tax': plan.name === 'free_v2' && isPromoVisible && !plan.priceForUser?.isThereATax,
               }"
          >
            <div
              class="adm-billing-dialog-plans-card__price-section__value__price"
              :class="{'is-blue': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible),
                       'promo-billing-2024': isPromoVisible,
                       'promo-billing-2024-discount': plan.coupon,
                       'promo-billing-2024-discount-price': getPlanPrice().toString().length > 6
              }"
            >
              {{ getPlanPrice() }}
              <span
                class="adm-billing-dialog-plans-card__price-section__value__price__interval"
                :class="{'is-blue': plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible)}"
              >
                <template v-if="isPromoVisible">
                  /mo
                </template>
                <template v-else>
                  / {{ $t('month') }}
                </template>
              </span>
            </div>
          </div>
          <!-- /Plan Price -->

          <!-- Plan Tax -->
          <div
            v-if="plan.name !== 'free_v2' && plan.price?.tax > 0"
            class="adm-billing-dialog-plans-card__price-section__tax"
            :class="{
              'is-blue': plan.name === 'scaling_v2',
              'promo-billing-2024': isPromoVisible,
              'mt-0': isPromoVisible && plan.price?.tax > 0 && plan.name === 'enterprise',
            }"
          >
            +{{ getPlanTax() }} {{ $t('vat') }}
          </div>
          <!-- Plan /Tax -->

        </div>
        <adm-skeleton-element
          v-else
          :height="100"
          :margin-top="12"
          :width="120"
          class="adm-billing-dialog-plans-card__price-section"
          :class="{'promo-billing-2024': isPromoVisible}"
        />
        <!-- /Plan Price and Tax -->

        <!-- Plan Status -->
        <div class="adm-billing-dialog-plans-card__status"
             :class="{
               'promotion': isPromoVisible && (plan.name === 'scaling_v2' || plan.name === 'starter_v2'),
               'mt-12': isPromoVisible && plan.price?.tax > 0 && (plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible)),
               'enterprise': isPromoVisible && plan.price?.tax > 0 && plan.name === 'enterprise'}"
        >
          <adm-button
            v-if="plan.name === 'enterprise'"
            class="adm-billing-dialog-plans-card__status__button"
            @click="contactUs"
          >
            {{ $t('contact_us') }}
          </adm-button>

          <template
            v-else
          >
            <!-- Change -->
            <adm-button
              v-if="isPromoVisible && (plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible))"
              class="adm-billing-dialog-plans-card__status__button-promotion"
              type="ghost"
              color="blue"
              full-width
              @click="onChange"
            >
              {{ $t('grab_now') }}
            </adm-button>
            <!-- /Change -->

            <adm-button
              v-if="visibleChange && !isPromoVisible"
              class="adm-billing-dialog-plans-card__status__button"
              :type="plan.name === 'scaling_v2' ? 'ghost' : 'filled'"
              color="blue"
              @click="onChange"
            >
              {{ $t('change') }}
            </adm-button>
            <!-- /Change -->

            <!-- Change Number of Users -->
            <adm-button v-if="visibleChangeNumberUsers" class="adm-billing-dialog-plans-card__status__button__users-number" @click="onChangeNumberOfUsers">
              {{ $t('change_number_of_users') }}
            </adm-button>
            <!-- /Change Number of Users -->

            <!-- Choose -->
            <adm-button v-if="visibleChoose && !isPromoVisible" @click="onChoose">
              {{ $t('choose') }}
            </adm-button>
            <!-- /Choose -->

          </template>

          <!-- Skeleton -->
          <adm-skeleton-element
            v-if="loading === true"
            class="adm-billing-dialog-plans-card__status__content"
            :height="36"
            :width="140"
          />
          <!-- /Skeleton -->

        </div>
        <!-- /Plan Status -->

        <!-- Includes -->
        <div class="adm-billing-dialog-plans-card__includes"
             :class="{'mt-12': isPromoVisible && (plan.name === 'scaling_v2' || (plan.name === 'starter_v2' && isPromoVisible))}"
        >
          <div v-if="loading === false">
            <div
              class="adm-billing-dialog-plans-card__includes__title"
              :class="{'is-blue': plan.name === 'scaling_v2' && !isPromoVisible}"
            >
              {{ getIncludesTitle(plan) }}:
            </div>
            <div
              v-for="include in getIncludes(plan)"
              :key="include"
              class="adm-billing-dialog-plans-card__includes__item"
            >
              <adm-icon icon="check" :color="$colors['--primary-900']" />
              <span :class="{'is-blue':plan.name === 'scaling_v2' && !isPromoVisible}">
                {{ include }}
              </span>
            </div>
          </div>
          <div v-else>
            <adm-skeleton-element
              v-for="i in 11"
              :key="i"
              :height="24"
              class="adm-billing-dialog-plans-card__includes__item"
            />
          </div>
        </div>
        <!-- /Includes -->
      </div>
    </div>
  </div>
  <!-- /Billing Checkout Dialog Card -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import mixinPlans from '@/mixins/plans/plans'
import mixinPrice from '@/mixins/common/price'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'BillingDialogPlansCard',

  components: {
    AdmButton,
    AdmIcon,
    AdmSkeletonElement,
  },

  mixins: [
    mixinPlans,
    mixinPrice
  ],

  props: {
    plan: {
      type: [Object, Number],
      default: () => {
      }
    },
    loading: {
      type: Boolean,
      default: true,
      required: true
    },
  },

  computed: {
    ...mapState({
      billingInterval: state => state.billing.billingInterval,
      currencySymbol: state => state.billing.currencySymbol,
      selectedPlan: state => state.billing.selectedPlan,
      tenantInfo: state => state.billing.tenantInfo,
      features: state => state.features.featuresList,
    }),

    ...mapGetters('billing', [
      'isSubscriptionPaused',
      'isPlanChosen'
    ]),

    isPromoVisible () {
      return ['free', 'free_v2', 'free_trial'].includes(this.tenantInfo.plan.name)
    },

    visibleActivePlan () {
      if (this.loading === true) {
        return false
      }

      if (this.tenantInfo === null) {
        return false
      }

      if (this.isPlanChosen === false) {
        return false
      }

      if (this.tenantInfo.plan.name === 'free_v2' && this.plan.name === 'free_v2') {
        return true
      }

      return this.plan.name === this.tenantInfo.plan.name &&
        this.plan.billingInterval === this.tenantInfo.plan.billingInterval &&
        (!this.tenantInfo.subscription || this.tenantInfo.subscription.status !== 'deleted')
    },

    visibleChange () {
      if (this.loading === true) {
        return false
      }

      if (this.tenantInfo === null) {
        return false
      }

      if (this.isPlanChosen === false) {
        return false
      }

      if (this.tenantInfo.plan.name === 'free_v2' && this.plan.name === 'free_v2') {
        return false
      }

      return this.tenantInfo.plan.name !== this.plan.name ||
          this.tenantInfo.plan.billingInterval !== this.plan.billingInterval
    },

    visibleChoose () {
      if (this.loading === true) {
        return false
      }

      if (this.tenantInfo === null) {
        return false
      }

      return this.isPlanChosen === false || this.isSubscriptionPaused
    },

    visibleChangeNumberUsers () {
      if (this.loading === true) {
        return false
      }

      if (this.tenantInfo === null) {
        return false
      }

      if (this.isPlanChosen === false) {
        return false
      }

      if (this.tenantInfo.plan.name !== this.plan.name) {
        return false
      }

      if (this.tenantInfo.plan.name === 'free_v2' && this.plan.name === 'free_v2') {
        return true
      }

      return this.tenantInfo.plan.name === this.plan.name &&
          this.tenantInfo.plan.billingInterval === this.plan.billingInterval
    },

    shouldDeactivateEntity () {
      const statistics = this.tenantInfo.statistics
      let activeFeatures = 0;

      for (const prop in this.features) {
        if (this.features[prop].isFree) {
          continue
        }

        const isFeatureAvailableInPlan = this.$store.getters['features/isFeatureAvailableInPlan'](prop, this.selectedPlan.planAvailableFeatures)

        if (this.features[prop].isEnabled && isFeatureAvailableInPlan) {
          ++activeFeatures
        }
      }

      return (this.selectedPlan.maxNumberOfCustomFeatures && activeFeatures > this.selectedPlan.maxNumberOfCustomFeatures) ||
        (this.selectedPlan.maxNumberOfEmployees && statistics.employees.value > this.selectedPlan.maxNumberOfEmployees)
    },

    isActive () {
      return this.loading === false &&
        this.tenantInfo !== null &&
        this.plan.name === this.tenantInfo.plan.name &&
        this.plan.billingInterval === this.tenantInfo.plan.billingInterval &&
        this.isPlanChosen === true
    },
  },

  methods: {
    onChoose () {
      this.$store.commit('billing/setSelectedPlan', this.plan)
      this.$store.commit('billing/setDowngradingToFree', false)

      if (this.shouldDeactivateEntity || this.plan.name === 'free_v2') {
        this.$emit('change-step', 'deactivate')
        return
      }

      this.$emit('change-step', 'checkout')
    },

    onChange () {
      this.$store.commit('billing/setSelectedPlan', this.plan)

      if (this.plan.name === 'free_v2') {
        this.$emit('change-step', 'free-downgrade')
        this.$store.commit('billing/setDowngradingToFree', true)

        return
      }

      if (this.tenantInfo.plan.name === 'free_v2' ||
          this.tenantInfo.subscription && this.tenantInfo.subscription.status === 'deleted'
      ) {
        this.$emit('change-step', 'checkout')

        return;
      }

      this.$store.commit('billing/setDowngradingToFree', false)
      this.$emit('change-step', 'change')
    },

    onChangeNumberOfUsers () {
      this.$store.commit('billing/setSelectedPlan', this.plan)
      this.$emit('change-step', 'changeNumberOfUsers')
    },

    getPlanPrice (withCoupon = true) {
      if (this.plan.name === 'free_v2') {
        return this.formatPlanPrice('0')
      }

      let price = '-'

      if (this.plan.price) {
        price = this.plan.price.net

        if (withCoupon && this.plan.coupon) {
          price = price - price * this.plan.coupon.value
        }
      }

      if (this.plan.billingInterval === 'yearly') {
        price = price / 12
      }

      return this.formatPlanPrice(this.roundedToFixed(price, 2))
    },

    getPlanTax() {
      let tax = '-'
      if (this.plan.price) {
        tax = this.plan.price.tax
      }

      if (this.plan.billingInterval === 'yearly') {
        tax = tax / 12
      }

      return this.formatPlanPrice(this.roundedToFixed(tax, 2))
    },

    roundedToFixed (amount, digits) {
      if (amount % 1 !== 0 && amount.toFixed(digits) % 1 !== 0) {
        return amount.toFixed(digits)
      }

      return amount.toFixed(0)
    },
  }
}
</script>

<style lang="scss">

// Plan Card
.adm-billing-dialog-plans-card {
  width: 285px;

  @include phone-down {
    width: 100%;
  }

  // Container
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $adm-white;
    box-shadow: 0 1px 1px rgba(0, 20, 60, 0.21), 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    padding: 24px 20px 24px;
    position: relative;

    &.is-blue {
      background: linear-gradient(180deg, #064EC6 0%, #000D27 100%);
      border-radius: 8px;
    }

    &.promotion {
      background-image: url("@/assets/img/black-friday-promotion/plan-card-bg.png");
      width: 285px;

      @include phone-down {
        width: 100%;
        max-width: 768px;
        background: url("@/assets/img/black-friday-promotion/plan-card-bg-mobile.png") no-repeat;
      }
    }

    &__free-promo-discount {
      position: absolute;
      left: 105px;
      top: -40px;
      color: white;
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      letter-spacing: 0.20px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url("@/assets/img/black-friday-promotion/diamond-promotion.png") no-repeat;
      width: 76px;
      height: 81px;

      @include phone-down {
        left: 45%;
      }
    }

    // Active Plan
    &__active-plan {
      position: absolute;
      right: 20px;
      top: 14px;
      color: $green-900;

      &.is-blue {
        color: $adm-white;
      }
    }

    // Active
    &.is-active {
      box-shadow: 0 0 0 1px var(--primary-900);
    }

    // Best Value Plan
    &__best-value {
      display: none;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: -40px;
      right: 0;
      z-index: 3;

      &--promotion {
        right: -40px;
        top: -45px;
      }

      @include tablet-up {
        display: flex;

        &:lang(it) {
          width: 100%;
        }
      }

      &__tooltip {
        display: flex;
        flex-direction: column;
        align-items: center;

        &__bullet {
          display: flex;
          height: 10px;

          &--promotion {
            svg circle {
              fill: $yellow-900;
            }
          }
        }

        &__frame {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 3px 12px;
          background: var(--primary-400);
          border-radius: 36px;
          border: 1px solid var(--primary-900);
          margin-bottom: 20px;

          &--promotion {
            background: $yellow-900;
            border: 1px solid $yellow-900;
          }

          &__content {
            color: var(--primary-900);
            font-weight: 600;
            font-size: 15px;
            line-height: 24px;
            text-align: center;

            &:lang(it) {
              font-size: 13px;
            }

            &--promotion {
              color: black;
            }
          }
        }
      }
    }

    svg {

    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }

  &__wrapper:last-child {
    flex: 1;
  }

  // Name
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $shade-900;
    margin-bottom: 4px;

    &.promo-billing-2024 {
      justify-content: center;
      padding: 12px 12px 0 12px;
      flex-wrap: wrap;
      flex-direction: column;
    }

    &__name {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;

      &.promo-billing-2024 {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0.24px;
        color: $shade-900;
      }

      &.is-blue {
        color: $adm-white ;
      }
    }

    &__black-friday-users {
      font-size: 14px;
      font-weight: 450;
      line-height: 22px;
    }

    &.is-blue {
      color: $adm-white;
    }

    // Saving
    &__badge {
      display: flex;
      padding: 4px 8px;
      background: $yellow-400;
      border-radius: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;

      &--active {
        background: $green-400;
        color: $green-900;
      }

      &--saving {
        color: $yellow-1000;

        &.is-blue {
          background: rgba(0, 20, 60, 0.3);
        }
      }
    }
  }

  &__price-section {
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include phone-up {
      min-height: 76px;
    }

    &.promo-billing-2024 {
      min-height: unset;
      margin: 10px auto;
    }

    &.promo-billing-2024-free {
      min-height: 143px;
      margin: 0 auto;
    }

    &.promo-billing-2024-free-tax {
      margin-top: 20px;
      min-height: 95px;
    }

    &.promo-billing-2024-enterprise-tax {
      margin-top: 20px;
      min-height: 115px;
    }

    &.promo-billing-2024-enterprise {
      min-height: 99px;
      margin-top: 15px;
    }

    &__starting {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      color: var(--primary-900);

      &.promo-billing-2024 {
        margin-top: 30px;
        font-weight: 450;
        font-size: 16px;
        text-align: center;
      }
    }

    &__value {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      justify-content: flex-start;

      &.promo-billing-2024-free {
        margin-top: 35px;
      }

      &.promo-billing-2024-free-tax {
        margin-top: 30px;
      }

      &.promo-billing-2024 {
        position: relative;
        background-size: cover;
        background-position: right;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        color: $adm-white;
        margin: 0 auto;
        align-items: baseline;
        padding: 2px 28px;
      }

      // Price
      &__price {
        display: flex;
        font-weight: 600;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0.01em;
        color: var(--primary-900);
        margin-right: 4px;
        line-height: 125%;
        align-items: baseline;

        &.is-blue {
          color: $adm-white;
        }

        &__interval {
          color: var(--primary-900);
          display: flex;
          align-items: end;
          font-weight: 500;
          font-size: 17px;
          letter-spacing: 0.34px;
          line-height: 130%;

          &.is-blue {
            color: $adm-white;
          }
        }

        &.promo-billing-2024 {
          font-size: 42px;
          letter-spacing: 0.42px;
        }

        &.promo-billing-2024-discount {
          color: var(--primary-900);

          &.is-blue {
            color: $adm-white;
          }
        }

        &.promo-billing-2024-discount-price {
          font-size: 28px;
          align-self: center;
        }
      }
    }

    // Tax
    &__tax {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: left;
      color: rgba(0, 20, 60, 0.70);
      font-style: italic;
      text-transform: uppercase;

      &.promo-billing-2024 {
        text-align: center;
        margin-top: 4px;
      }

      &.is-blue {
        color: rgba(255, 255, 255, 0.70);
      }
    }
  }

  // Billed annually
  &__billed {
    margin-top: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: var(--primary-900);

    &.is-blue {
      color: $adm-white;
    }
  }

  // Coupon
  &__coupon {

    &__tag {
      background: #FF00E5;
      border: 1px solid #FF00E5;
      box-shadow: 0 0 10px #FB36FF;
      color: $adm-white;
      text-transform: uppercase;
      font-weight: 600;
      text-align: center;
      padding: 5px 10px;
    }

    &__without-coupon {
      position: relative;
      text-align: center;


      &.promo-billing-2024-discount-price {
        font-size: 20px;
      }

      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 0;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;
        opacity: 1;
        color: #005AEE;
      }

      &.is-blue:before  {
        color: $adm-white;
      }

      span {
        font-size: 24px;
        font-weight: 450;
        color: rgba(0, 20, 60, 0.70);
        line-height: normal;

        &.is-blue {
          color: rgba(255, 255, 255, 0.70);

          &.promotion{
            color: $adm-white;
          }
        }
      }

      &.is-blue {
        color: $adm-white;
      }
    }
  }

  // Status
  &__status {
    text-align: center;
    margin-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 12px;
    border-bottom: 1px solid $shade-300;

    &.promotion {
      border-bottom: unset;
    }

    &.enterprise {
      margin-top: 6px !important;
    }

    &__button {
      width: 100%;

      &__users-number {
        max-width: 100%;
      }
    }

    &__button-promotion {
      padding: 8px 24px;
      border-radius: 0 !important;
    }

    // Status Content
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      width: 120px;
    }
  }

  // Includes
  &__includes {
    &__title {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 12px;

      &.is-blue {
        color: $adm-white
      }
    }

    &__item {
      display: flex;
      align-items: flex-start;
      margin-bottom: 0.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      span {
        font-weight: normal;
        margin-left: 0.25rem;
        font-size: 13px;
        line-height: 24px;

        &.is-blue {
          color: var(--primary-400);
        }
      }
    }
  }
}
</style>
